import { useEffect, useState } from "react";
import { getBoletas } from "./Utilities";

export default function BusquedaBoleta() {
  const [boletas, setBoletas] = useState([]);

  useEffect(() => {
    fetchBoletas();

  }, [])

  const fetchBoletas = () => {
    const data = getBoletas();
    data
      .then(function (response) {
        setBoletas(response);
      })
      .catch(function (error) {
      });
  }
  
  return (
    <div id='container' className="container-lg ">
      <div id='title' className="row" style={{ margin: 50 }}>
        <div id='titlediv' className="col align-self-center text-center">
          <h3>Búsqueda de Boletas</h3>
        </div>
      </div>

      <hr></hr>
      <h4 style={{color: 'grey'}}>Resultados</h4>

      <div className="table-responsive">
      <table className="table table-striped table-bordered">
      <thead className="thead-light">
        <tr>
          <th scope="col">Número Boleta</th>
          <th scope="col">Cliente</th>
          <th scope="col">Mantenimiento</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {
          boletas.map(({idboleta, tipomantenimiento, cliente}) => (
            <tr>
              <th className='col '>{idboleta}</th>
              <th >{cliente}</th>
              <th className='col '>{tipomantenimiento}</th>
              <a href={'/boleta/'+idboleta} >
                <img src='edit.png' width="25px" style={{margin: 5}}></img>
              </a>
            </tr>
          ))
        }
      </tbody>
      </table>
      </div>
      <div className='row' style={{ margin: 50 }}></div>
    
    </div>
    
  )
}
