import { getBoletas, getBoletasNA, getClientes, getServer, getTecnicos } from "./Utilities";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Pizarra(props) {
  const [boletas, setBoletas] = useState([]);
  const [boleta, setBoleta] = useState({
    idboleta: '',
    idetecnico: ''
  });
  const [mensaje, setMensaje] = useState('');
  const [tecnicos, setTecnicos] = useState([]);
  const [messeleccionado, setmesseleccionado] = useState(new Date().getMonth()+1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBoletas();
    fetchTecnicos();
  }, [])

  const changeBoleta = (event) => {
    setmesseleccionado (event.target.value);
    const data = getBoletasNA(event.target.value);
    data
      .then(function (response) {
        setBoletas(response);
      })
      .catch(function (error) {
      });
  }

  const fetchBoletas = () => {
    const data = getBoletasNA(messeleccionado);
    data
      .then(function (response) {
        setBoletas(response);
      })
      .catch(function (error) {
      });
  }

  const fetchTecnicos = () => {
    const data = getTecnicos();
    data.then(function (response) {
      setTecnicos(response);
    }).catch(function (error) {

    });
  }

  const clearForm = () => {
    setBoleta({
      idboleta: '',
      idetecnico: ''
    });
  }

  const eliminarBoleta = (event, ideBoleta) => {
    const postData = {
      action: "deleteBoleta",
      idboleta: ideBoleta,
    };

    const formData = new FormData();
    formData.append('datos', JSON.stringify(postData));

    const axios = require('axios').default;
    axios.post(getServer() + '/actions/boletas.php', formData)
      .then(function (response) {
        fetchBoletas();
        setMensaje('Datos eliminados existosamente!')
      }).catch(function (error) {
        setMensaje('Error eliminando los datos!!!')
      });
  }

  const saveBoleta = () => {
    const postData = {
      action: "assign",
      idetecnico: boleta.idetecnico,
      idboleta: boleta.idboleta,
    };

    const axios = require('axios').default;
    axios.post(getServer() + '/actions/asigboletas.php', postData)
      .then(function (response) {
        fetchBoletas();
        setMensaje('Datos guardados existosamente!')
      }).catch(function (error) {
        setMensaje('Error guardando los datos!!!')
      });
  }

  const asignarTecnico = (event, idboleta, idetecnico) => {
    if (!idetecnico) {
      idetecnico = '';
    }

    setBoleta({
      idetecnico: idetecnico,
      idboleta: idboleta
    });
  }

  const handleInputChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;

    setBoleta({ ...boleta, [id]: value });
  }

  const tec = props.tecnico === '1';

  return (
    <div id='container' className="container-lg ">
      <div id='title' className="row" style={{ margin: 50 }}>
        <div id='titlediv' className="col align-self-center text-center">
          <h3>Pizarra de Boletas</h3>
        </div>
      </div>

      <form onSubmit={saveBoleta}>
        <div className="row">
          <div className="col">
            <div className="mb-3" >
              <label className="form-label">Boleta</label>
              <input className="form-control" value={boleta.idboleta} onChange={handleInputChange} />
            </div>
            <div className="mb-3" >
              <label className="form-label">Preparado por</label>
              <select className="form-select" id='idetecnico' value={boleta.idetecnico} onChange={handleInputChange} >
                <option disabled={true} value="">Escoja el técnico</option>
                {
                  tecnicos.map(({ idetecnico, tecnico, index }) => (
                    <option key={index} value={idetecnico}>{tecnico}</option>
                  ))
                }
              </select>
            </div>
            <div className="mb-3" align="center">
              <button type="submit" className="btn"><img src='/save.png' className='rounded' width="50px" /></button>
              <button type="button" className="btn"><img src='/limpiar.png' className='rounded' width="50px" alt='Limpiar formulario' title='Limpiar' onClick={clearForm} /></button>
              <a href='#' onClick={() => navigate(-1)}><img className="rounded" src='/back.png' width="50px" style={{ margin: 5 }} title="Regresar"></img></a>
            </div>
          </div>
        </div>
      </form>

      <div id='messagebar' className="col align-self-center text-center alert alert-secondary">
        <label>{mensaje}</label>
      </div>

      <div className="row">
        <div className="col"><h4>Lista de Boletas</h4></div>
        <div className="col" ><select className="form-control" value={messeleccionado} onChange={changeBoleta}>
          <option value={1}>Enero</option>
          <option value={2}>Febrero</option>
          <option value={3}>Marzo</option>
          <option value={4}>Abril</option>
          <option value={5}>Mayo</option>
          <option value={6}>Junio</option>
          <option value={7}>Julio</option>
          <option value={8}>Agosto</option>
          <option value={9}>Setiembre</option>
          <option value={10}>Octubre</option>
          <option value={11}>Noviembre</option>
          <option value={12}>Diciembre</option>
        </select></div>
      </div>

      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Número</th>
              <th scope="col">Estado</th>
              <th scope="col">Cliente</th>
              <th scope="col">Equipo</th>
              <th scope="col">Técnico</th>
              <th scope="col">Fecha</th>
              <th scope="col">Fecha máximo</th>
              <th scope="col">Tipo</th>
            </tr>
          </thead>
          <tbody>
            {
              boletas.map(({ idboleta, cliente, tecnico, equipo, tipomantenimiento, idetecnico, fecha, fechafinal, idreporte }) => (
                <tr>
                  <th scope="row">{idboleta}</th>
                  <td>{(idreporte)? <img src='/check.png' width={25}/> : <img src='/uncheck.png' width={25}/> }</td>
                  <td>{cliente}</td>
                  <td>{equipo}</td>
                  <td>{(tecnico) ? tecnico : 'No asignado'}</td>
                  <td>{fecha}</td>
                  <td>{fechafinal}</td>
                  <td>{tipomantenimiento}</td>
                  <td>
                    <div className='col align-self-center text-center'>
                      <a href='#' onClick={(event) => asignarTecnico(event, idboleta, idetecnico)}>
                        <img className="rounded" src='/assign.png' width="25px" style={{ margin: 5 }} title="Asignar técnico"></img>
                      </a>
                      <a href={'/boleta/' + idboleta}>
                        <img className="rounded" src='/edit.png' width="25px" style={{ margin: 5 }} title="Editar boleta"></img>
                      </a>
                      <a href='#' onClick={(event) => eliminarBoleta(event, idboleta)} hidden={tec}>
                        <img className="rounded" src='/delete.png' width="25px" style={{ margin: 5 }} title="Eliminar boleta"></img>
                      </a>
                    </div>
                  </td>
                </tr>
              ))
            }

          </tbody>
        </table>
      </div>

      <div className="row" style={{ margin: 50 }}></div>

    </div>
  )
}
