import 'bootstrap/dist/js/bootstrap.bundle';
import { getServer } from './Utilities';

export default function Menu(props) {

  function doLogout(e) {
    //logout in the server
    const axios = require('axios').default;
  
    e.preventDefault();
  
    axios.post(getServer() + '/actions/logout.php')
      .then(function (response) {        
        // logout in the client
        sessionStorage.clear();
        window.location.pathname = '/';
      })
      .catch(function (error) {
 
      })
      .then(function () {
        // always executed
      });
  };
  
  const tec = props.tecnico === '1';
  if(props.noheader) return null;

  return (
    <div className="d-flex aligns-items-center justify-content-center" >
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/"><img className="rounded" src='/logo-solutecsa.jpeg' alt="" width="30" height="24"></img> SOLUTECSA</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">Inicio</a>
              </li>
              <li className="nav-item dropdown" hidden={tec}>
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Reportes
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a className="dropdown-item" href="/reporte">Reporte</a></li>
                  <li><a className="dropdown-item" href="/busquedareporte">Búsqueda</a></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Boletas
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a className="dropdown-item" href="/boleta">Boleta</a></li>
                  <li><a className="dropdown-item" href="/pizarra">Pizarra</a></li>
                </ul>
              </li>
              <li className="nav-item dropdown"  hidden={tec}>
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Mantenimiento
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a className="dropdown-item" href="/cliente">Clientes</a></li>
                  <li><a className="dropdown-item" href="/equipo">Equipo</a></li>                  
                  <li><a className="dropdown-item" href="/tecnico">Técnicos</a></li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" onClick={doLogout}>Salir</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}