import React, { useEffect, useState } from 'react';
import { getServer } from './Utilities';

export default function Tecnico() {
  const [data, setData] = useState([]);
  const [mensaje, setMensaje] = useState('');
  const [currentTecnico, setCurrentTecnico] = useState({
    tecnico: '',
    telefono: '',
    clave: '',
    correo: '',
    direccion: '',
  });

  useEffect(() => {
    fetchTecnicos();
  }, []);

  const fetchTecnicos = () => {
    const axios = require('axios').default;

    axios.get(getServer() + '/actions/tecnicos.php')
      .then(function (response) {
        setData(response.data);
      })
      .catch(function (error) {
        // on error        
      })
      .then(function () {
        // always executed
      });
  }

  const clearForm = event => {
    setCurrentTecnico({
      tecnico: '',
      telefono: '',
      clave: '',
      correo: '',
      direccion: '',
    });
    setMensaje("");
  }

  const viewTecnico = (event, id) => {
    const c = data.filter(obj => obj.idetecnico === id);
    setMensaje("");

    console.log(data);

    if (c.length > 0) {
      setCurrentTecnico(c[0]);
    }
  };

  const handleInputChange = (event) => {
    setMensaje("");
    const target = event.target;
    const value = target.value;

    switch (target.id) {
      case 'nombre':
        currentTecnico.tecnico = value;
        break;
      case 'telefono':
        currentTecnico.telefono = value;
        break;
      case 'correo':
        currentTecnico.correo = value;
        break;
      case 'clave':
        currentTecnico.clave = value;
        break;
      case 'direccion':
        currentTecnico.direccion = value;
        break;
      default:
        break;
    }

    setCurrentTecnico(currentTecnico);

    const newData = data.map(obj => {
      if (obj.idetecnico === currentTecnico.idetecnico) {
        obj = currentTecnico;
      }

      return obj;
    });

    setData(newData);
  };

  const deleteTecnico = (event, idetecnico) => {
    const postData = {
      action: 'delete',
      data: idetecnico
    };

    const axios = require('axios').default;

    axios.post(getServer() + '/actions/tecnicos.php', postData)
      .then(function (response) {
        clearForm();
        fetchTecnicos();
        setMensaje("Datos eliminados existosamente !!!");
      })
      .catch(function (error) {
        setMensaje("Error eliminando datos ");
      })
      .then(function () {
        // always executed
      });
  }

  const saveTecnico = event => {
    event.preventDefault();
    const postData = {
      action: '',
      data: {}
    };

    postData.action = (currentTecnico.idetecnico === undefined) ? 'save' : 'update';
    postData.data = currentTecnico;

    const axios = require('axios').default;

    axios.post(getServer() + '/actions/tecnicos.php', postData, {timeout: 2000})
      .then(function (response) {        
        clearForm();
        fetchTecnicos();
        setMensaje("Datos guardados existosamente !!!");
      })
      .catch(function (error) {
        setMensaje("Error guardando datos ");
      })
      .then(function () {
        // always executed
      });
  }

  return (
    <div className="container-lg ">
      <div className="row" style={{ margin: 50 }}>
        <div className="col align-self-center text-center">
          <h3>Mantenimiento de Técnicos</h3>
        </div>
      </div>
      <form onSubmit={saveTecnico}>
        <div className="row">
          <div className="col">
            <div className="mb-3" >
              <label className="form-label">Nombre y Apellidos</label>
              <input type="text" className="form-control" id="nombre" value={currentTecnico.tecnico} required onChange={handleInputChange}/>
            </div>
            <div className="mb-3" >
              <label className="form-label">Teléfono</label>
              <input type="phone" className="form-control" id="telefono"  value={currentTecnico.telefono} onChange={handleInputChange}/>
            </div>
          </div>
          <div className="col">
          <div className="mb-3" >
              <label className="form-label">Correo Electrónico</label>
              <input type="email" className="form-control" id="correo"  value={currentTecnico.correo} onChange={handleInputChange}/>
            </div>
            <div className="mb-3" >
              <label className="form-label">Dirección</label>
              <input type="text" className="form-control" id="direccion"  value={currentTecnico.direccion} onChange={handleInputChange}/>
            </div>
            <div className="mb-3" align="center">
              <button type="submit" className="btn"><img src='/save.png' className='rounded' width="50px" /></button>
              <button type="button" className="btn"><img src='/limpiar.png' className='rounded' width="50px" alt='Limpiar formulario' title='Limpiar' onClick={clearForm} /></button>
            </div>
          </div>
        </div>
      </form>
      <div className="col align-self-center text-center alert alert-secondary">
        <label>{mensaje}</label>
      </div>
      <div className="row" style={{ margin: 50 }}>
        <div className="col align-self-center text-center">
          <h3>Técnicos Registrados</h3>
        </div>
      </div>
      <div className='row border border-sucess text-center' >
        <div id='header' className='col border border-sucess'>Acción</div>
        <div id='header' className='col border border-sucess'>Nombre</div>
        <div id='header' className='col border border-sucess'>Dirección</div>
      </div>
      {
        data.map(({idetecnico, tecnico, direccion}) => (
          <div className='row'>
            <div className='col border border-sucess align-self-center text-center'>
              <a 
                href='#'
                onClick={event => viewTecnico(event, idetecnico)}
              >
                <img src='edit.png' width="25px" style={{margin: 5}}></img>
              </a>
              <a 
                href='#'
                onClick={event => deleteTecnico(event, idetecnico)}
              >
                <img src='delete.png' width="25px"></img>
              </a>
            </div>
            <div className='col border border-sucess'>{tecnico}</div>
            <div className='col border border-sucess'>{direccion}</div>
          </div>
        ))
      }
      <div className='row' style={{ margin: 50 }}></div>
    </div>
  );
}