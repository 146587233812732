export function getServer () {
  let server = '';

  if (process.env.NODE_ENV === 'development') {
    server = 'http://localhost/solutecsa';
  }

  return server;
}

export async function getClientes(){
  const axios = require('axios').default;
  const response = await axios.get(getServer() + '/actions/clientes.php', {timeout: 2000});
  return response.data;
}

export async function getTecnicos(){
  const axios = require('axios').default;
  const response = await axios.get(getServer() + '/actions/tecnicos.php');
  return response.data;
}

export async function getBoletasNA(mes){
  const axios = require('axios').default;
  const response = await axios.get(getServer() + '/actions/asigboletas.php?mes='+mes);
  return response.data;
}

export async function getEquipos(cliente){
  const axios = require('axios').default;
  let paramCliente = '';

  if(cliente){
    paramCliente = '?cliente=' + cliente;
  }

  const response = await axios.get(getServer() + '/actions/equipos.php' + paramCliente);
  return response.data;
}

export async function getBoletas(parameter, isBoleta){
  const axios = require('axios').default;
  let paramValue = '';

  if(isBoleta){
    paramValue = '?idboleta=' + parameter;
  }else {
    if(parameter) paramValue = '?cliente=' + parameter;
  }

  const response = await axios.get(getServer() + '/actions/boletas.php' + paramValue);
  return response.data;
}


export async function getReporte(idreporte){
  const axios = require('axios').default;
  let param = '';

  if(idreporte){
    param = '?idreporte=' + idreporte;
  }

  const response = await axios.get(getServer() + '/actions/reportes.php' + param);
  return response.data;
}

export async function getReporteByCliente(idcliente){
  const axios = require('axios').default;
  let param = '';

  if(idcliente){
    param = '?idcliente=' + idcliente;
  }

  const response = await axios.get(getServer() + '/actions/reportes.php' + param);
  return response.data;
}