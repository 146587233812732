import { useEffect, useState } from "react";
import { Col, Button, Row, Container, Card, Form, Alert } from "react-bootstrap";
import { getServer } from "./Utilities";

export default function Login({setToken, setLocalStorage}){
  const [usuario, setUsuario] = useState('');
  const [clave, setClave] = useState('');
  const [mensaje, setMensaje] = useState('');

  const handleInput = event => {
    setMensaje('');
    if (event.target.id === 'usuario'){
      setUsuario(event.target.value)
    }else if(event.target.id === 'clave'){
      setClave(event.target.value);
    }
  };

  const doLogin = (e) => {
    const axios = require('axios').default;
    e.preventDefault();

    axios.post(getServer() + '/actions/login.php', {
      'usuario': usuario,
      'clave': clave
    }).then(function (response) {
        const token = response.data.token;
        const tecnico = response.data.tecnico;
        
        sessionStorage.setItem('tecnico', tecnico);
        setLocalStorage(token, tecnico)
        setToken(token);
      })
      .catch(function (error) {
        setMensaje('Usuario o clave invalidos');
      })
      .then(function () {
        // always executed
      });
 
  }

  return (
    <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <Card className="shadow">
              <Card.Body>
                <img src="/logo-solutecsa.jpeg" alt="" width="30" height="24" />
                <label className="fw-bold mb-2 text-uppercase" style={{fontSize: 25}}>{'  '}SOLUTECSA Reportes</label>
                <div className="mb-3 mt-md-4">
                  
                    <div className="mb-3">
                    <Form onSubmit={doLogin}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          Usuario
                        </Form.Label>
                        <Form.Control type="text" placeholder="Ingrese su usuario" id='usuario' value={usuario} onChange={handleInput}/>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Clave</Form.Label>
                        <Form.Control type="password" placeholder="Clave" id='clave' value={clave} onChange={handleInput}/>
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="secondary" type="submit" >
                          Ingresar
                        </Button>
                        <div className="d-grid text-center secondary"><Alert variant={mensaje === ''? 'light':'danger'}>{mensaje}</Alert></div>
                      </div>
                      
                    </Form>

                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}