import React, { useEffect, useState } from 'react';
import { getBoletas, getClientes, getEquipos, getServer, getTecnicos } from './Utilities';
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from 'react-datepicker';
import { Button } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import CanvasDraw from 'react-canvas-draw';

export default function Boleta() {
  const reader = new FileReader();
  const { idboleta } = useParams();
  const navigate = useNavigate();
  let firmat, firmac = null;

  const [detallesToDelete, setDetallesToDelete] = useState([]);
  const [save, setSave] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [tecnicos, setTecnicos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [equipos, setEquipos] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [startFDate, setStartFDate] = useState(new Date());
  const [detalles, setDetalles] = useState([]);
  const [finalTecnico, setFinalTecnico] = useState(false);
  const [finalCliente, setFinalCliente] = useState(false);
  const [optionTecnico, setOptionTecnico] = useState(0);
  const [optionCliente, setOptionCliente] = useState(0);
  const [boleta, setBoleta] = useState({
    idecliente: '',
    idequipo: '',
    tipomantenimiento: '',
    idetecnico: '0',
    reporte: '',
    firma: '',
    fcliente: '',
    inicio: '',
    final: '',
    observaciones: '',
    recomendaciones: '',
    nombrecliente: '',
    nombretecnico: ''
  });

  const clearBoleta = (event) => {
    setBoleta({
      idecliente: '',
      idequipo: '',
      tipomantenimiento: '',
      idetecnico: '',
      reporte: '',
      firma: '',
      fcliente: '',
      inicio: '',
      final: '',
      observaciones: '',
      recomendaciones: ''
    });
    setStartDate(new Date());
    setStartFDate(new Date());
    setDetalles([]);
  }

  useEffect(() => {
    fetchEmpresas();
    fetchTecnicos();

    if (idboleta) {
      const data = getBoletas(idboleta, true);
      data.then(function (response) {
        setBoleta({
          ...response[0]
        });

        setStartDate(new Date(response[0].fecha));
        if (response[0].fechafinal) {
          setStartFDate(new Date(response[0].fechafinal));
        }

        setDetalles(
          response['detalles'].map((d) => ({ ...d, src: '/actions/images/' + d.idboleta + d.archivo, change: false }))
        );
        fetchEquipos(boleta.idecliente);
      })
    }
  }, [idboleta])

  const fetchEmpresas = () => {
    const data = getClientes();
    data
      .then(function (response) {
        setClientes(response);
      })
      .catch(function (error) {
        setMensaje('Error cargando datos !!!')
      });
  }

  const fetchEquipos = (event) => {
    let idecliente = '';

    if (event) {
      idecliente = event.target.value;
      onInputChange(event);
    } else {
      idecliente = event;
    }

    const data = getEquipos(idecliente);

    data
      .then(function (response) {
        setEquipos(response);
      })
      .catch(function (error) {
        setMensaje('Error cargando datos !!!')
      });
  }

  const fetchTecnicos = () => {
    const data = getTecnicos();
    data
      .then(function (response) {
        setTecnicos(response);
      })
      .catch(function (error) {
        setMensaje('Error cargando datos !!!')
      });
  }

  const addDetalle = (e) => {
    setDetalles([...detalles, { detalle: '', foto: '', new: true }]);
    setSave(false);
  }

  const deleteDetalle = (event, i) => {
    const ds = detalles.filter((detalle, index) => index !== i);

    const d = detalles[i];
    if (d.iddetalleboleta) {
      setDetallesToDelete([...detallesToDelete, detalles[i]]);
    }

    setDetalles([...ds]);
    setSave(false);
  }

  const onchangeDetalle = (event, index) => {
    const ds = detalles;

    if (event.target.id === 'foto') {
      const file = event.target.files[0];
      ds[index].archivo = file;
      ds[index].foto = file.name;
      ds[index].change = true;

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        loadImage(index);
      }
    } else {
      ds[index][event.target.id] = event.target.value;
      ds[index]['change'] = true;
    }
    setSave(false);
    setDetalles([...ds]);
  }

  const loadImage = (index) => {
    const ds = detalles;
    ds[index].src = reader.result;
    setSave(false);

    setDetalles([...ds]);
  }

  const onInputChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    setSave(false);

    setBoleta({ ...boleta, [id]: value });
  }

  const changeTecnicoOption = (ev) => {
    const id = ev.target.id;
    const value = ev.target.value;

    setOptionTecnico(value);
  }

  const changeClienteOption = (ev) => {
    const id = ev.target.id;
    const value = ev.target.value;

    setOptionCliente(value);
  }

  const limpiarfirmac = () => {
    if (!firmac) {
      boleta.fcliente = null;
      setBoleta({ ...boleta, fcliente: null });
      setFinalCliente(false);
    } else
      firmac.clear();
  }

  const limpiarfirmat = () => {
    setOptionTecnico(0);

    if (!firmat) {
      boleta.firma = null;
      setBoleta({ ...boleta, firma: null });
      setFinalTecnico(false);
    } else
      firmat.clear();
  }

  const renderNombreT = () => {
    return (
      <div id='nombretecnico' >
        <div className="row ">
          <div className='col'>
            <h6>Nombre técnico</h6>
          </div>
        </div>
        <div className="row">
          <div className='col'>
            <input type="text" className='form-control' id='nombretecnico' value={boleta.nombretecnico} onChange={onInputChange}/>
          </div>
          < div className='col'>

          </div>
        </div>
      </div>
    )
  }

  const renderFirmaT = () => {
    return (
      <div id='firmatecnico' >
        <div className="row">
          <div className='col'><h6>Firma técnico - fecha</h6></div>
        </div>

        <div className="row">
          <div className='col'>
            {
              boleta.firma ?
                <img src={boleta.firma} title="Firma técnico" alt='' />
                :
                <CanvasDraw
                  ref={canvasDraw => (firmat = canvasDraw)}
                  brushRadius={4}
                  lazyRadius={4}
                  canvasWidth={600}

                />
            }
          </div>
        </div>

        <div className='row'>
          <div className='col'><Button className='btn btn-primary' type='button' onClick={limpiarfirmat} variant='secondary'>Limpiar</Button></div>
          <div className='col'><Button className='btn btn-primary' type='button' hidden={boleta.firma ? true : false || finalTecnico} variant='secondary'
            onClick={() => { setFinalTecnico(true); setSave(false); }}>Aceptar Firma</Button></div>
        </div>
      </div>
    )
  }

  const renderTecnico = () => {
    let option = (optionTecnico === '2') ? renderNombreT() : (optionTecnico === '1') ? renderFirmaT() : null;

    if(option === null){
      if (boleta && boleta.firma){
        option = renderFirmaT();
      }

      if(boleta && boleta.nombretecnico){
        option = renderNombreT();
      }
    }

    return (
      <div>
        <div className="row">
          <div className="col">
            <label className="form-label"><h6>Seleccione la opción de tecnico</h6></label>
            <select className="form-select" id='changeoptiontecnico' value={optionTecnico} onChange={changeTecnicoOption}>
              <option disabled={true} value={0}>Escoja la opción</option>
              <option value={1}>Firma</option>
              <option value={2}>Nombre</option>
            </select>
          </div>
          <div className="col"></div>
        </div>

        {option}
      </div>

    );
  }

  const renderNombreC = () => {
    return (
      <div id='nombrecliente' >
        <div className="row ">
          <div className='col'>
            <h6>Nombre cliente</h6>
          </div>
        </div>
        <div className="row">
          <div className='col'>
            <input type="text" className='form-control' id='nombrecliente' value={boleta.nombrecliente} onChange={onInputChange}/>
          </div>
          < div className='col'>

          </div>
        </div>
      </div>
    );
  }

  const renderFirmaC = () => {
    return (
      <div id='firmacliente'>
          <div className="row">
            <div className='col'><h6>Recibido conforme cliente</h6></div>
          </div>

          <div className="row">
            <div className='col'>
              {
                boleta.fcliente ?
                  <img src={boleta.fcliente} title="Firma cliente" alt='' />
                  :
                  <CanvasDraw
                    ref={canvasDraw => (firmac = canvasDraw)}
                    brushRadius={4}
                    lazyRadius={4}
                    canvasWidth={600}
                  />
              }
            </div>
          </div>

          <div className="row">
            <div className='col'><Button className='btn btn-primary' type='button' onClick={limpiarfirmac} variant='secondary'>Limpiar</Button></div>
            <div className='col'><Button className='btn btn-primary' type='button' hidden={boleta.fcliente ? true : false || finalCliente} variant='secondary'
              onClick={() => { setFinalCliente(true); setSave(false); }}>Aceptar Firma</Button></div>
          </div>
        </div>
    )
  }

  const renderDDLCliente = () => {
    return (    
      <div className="row">
        <div className="col">
          <label className="form-label"><h6>Seleccione la opción de cliente</h6></label>
          <select className="form-select" id='changeoptioncliente' value={optionCliente} onChange={changeClienteOption}>
            <option disabled={true} value={0}>Escoja la opción</option>
            <option value={1}>Firma</option>
            <option value={2}>Nombre</option>
          </select>
        </div>
        <div className="col"></div>
      </div>)
  }

  const renderCliente = () => {
    let option = (optionCliente === '2') ? renderNombreC() : (optionCliente === '1') ? renderFirmaC() : null;

    if(option === null){
      if (boleta && boleta.fcliente){
        option = renderFirmaC();
      }

      if(boleta && boleta.nombrecliente){
        option = renderNombreC();
      }
    }

    return (
      <div>
        {renderDDLCliente()}

        {option}
      </div>
    );
  }

  const saveBoleta = (event) => {
    const postData = {
      action: '',
      data: {}
    };

    detalles.map(deta => deta.src = '');

    postData.action = (boleta.idboleta === undefined) ? 'save' : 'update';
    boleta.fecha = startDate;
    boleta.fechafinal = startFDate;
    boleta.detalles = detalles.filter((d) => d.change === true || d.new === true);
    boleta.optionTecnico = optionTecnico;

    if (optionTecnico === '1') boleta.nombretecnico = null;
    if (optionTecnico === '2') boleta.firma = null;
    if (optionCliente === '1') boleta.nombrecliente = null;
    if (optionCliente === '2') boleta.fcliente = null;
    if (firmac && finalCliente) boleta.fcliente = firmac.getDataURL();
    if (firmat && finalTecnico) boleta.firma = firmat.getDataURL();

    postData.data = boleta;

    const formData = new FormData();

    boleta.detalles.map((det, index) => (
      formData.append('file' + index, det.archivo)
    ));
    formData.append('datos', JSON.stringify(postData));

    const axios = require('axios').default;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    axios.post(getServer() + '/actions/boletas.php', formData, config)
      .then(function (response) {
        setSave(true);
        if (postData.action === 'save') {
          setBoleta({ ...boleta, idboleta: response.data });
        }
        boleta.detalles = '';
        setMensaje("Datos guardados existosamente !!!");
      })
      .catch(function (error) {
        console.log(error);
        setMensaje("Error guardando datos ");
      })
      .then(function () {
        // always executed
      });

    //deleting detalles
    const deleteData = {
      detalles: detallesToDelete,
      action: 'delete'
    };
    const fdata = new FormData();
    fdata.append('datos', JSON.stringify(deleteData));
    axios.post(getServer() + '/actions/boletas.php', fdata, config)
      .then(function (response) {
        setDetallesToDelete({});
      })
      .catch(function (error) {
      })
      .then(function () {
        // always executed
      });
  }

  return (
    <div className="container-lg ">
      <div className="row" style={{ margin: 50 }}>
        <div className="col align-self-center text-center">
          <h3>Boleta {(boleta.idboleta) ? '#' + boleta.idboleta : ''}</h3>
        </div>
      </div>

      <form>
        <div className="row">
          <div className="col">
            <div className="mb-3" >
              <label className="form-label">Cliente</label>
              <select className="form-select" id='idecliente' onChange={fetchEquipos} value={boleta.idecliente} >
                <option disabled={true} value="">Escoja un cliente</option>
                {
                  clientes.map(({ idecliente, cliente, index }) => (
                    <option key={index} value={idecliente}>{cliente}</option>
                  ))
                }
              </select>
            </div>
            <div className="mb-3" >
              <label className="form-label">Equipo</label>
              <select className="form-select" id='idequipo' value={boleta.idequipo} onChange={onInputChange} >
                <option disabled={true} value="">Escoja un equipo</option>
                {
                  equipos.map(({ idequipo, equipo, index }) => (
                    <option key={index} value={idequipo}>{equipo}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className="col">
            <div className="mb-3" >
              <label className="form-label">Tipo de Mantenimiento</label>
              <select className="form-select" id='tipomantenimiento' value={boleta.tipomantenimiento} onChange={onInputChange} >
                <option disabled={true} value="">Escoja el tipo de Mantenimiento</option>
                <option value={"Preventivo"}>Preventivo</option>
                <option value={"Correctivo"}>Correctivo</option>
              </select>
            </div>
            <div className="mb-3" >
              <label className="form-label">Preparado por</label>
              <select className="form-select" id='idetecnico' value={boleta.idetecnico} onChange={onInputChange} >
                <option disabled={true} value="0">Escoja el técnico</option>
                {
                  tecnicos.map(({ idetecnico, tecnico, index }) => (
                    <option key={index} value={idetecnico}>{tecnico}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className='col'><label>Fecha inicio</label></div>
          <div className='col'><ReactDatePicker className='form-control'
            selected={startDate} onChange={(date) => setStartDate(date)} /></div>
          <div className='col'></div>
        </div>
        <div className="row">
          <div className='col'><label>Fecha máxima de Ejecución</label></div>
          <div className='col'><ReactDatePicker className='form-control'
            selected={startFDate} onChange={(date) => setStartFDate(date)} /></div>
          <div className='col'></div>
        </div>
        <div className="row">
          <div className='col'><label>Hora de inicio</label></div>
          <div className='col'><input type="number" className='form-control' id='inicio' value={boleta.inicio} onChange={onInputChange} /></div>
          <div className='col'></div>
        </div>
        <div className="row">
          <div className='col'><label>Hora de final</label></div>
          <div className='col'><input type="number" className='form-control' id='final' value={boleta.final} onChange={onInputChange} /></div>
          <div className='col'></div>
        </div>

        <hr></hr>

        <div className="row">
          <div className='col'><h6>Reporte</h6></div>
        </div>
        <div className='row'><textarea type="text" className='form-control' id='reporte' rows={10} value={boleta.reporte} onChange={onInputChange}></textarea></div>

        <div className="row">
          <div className='col'><h6>Recomendaciones</h6></div>
        </div>
        <div className='row'><textarea type="text" className='form-control' id='recomendaciones' rows={5} value={boleta.recomendaciones} onChange={onInputChange}></textarea></div>

        <div className="row">
          <div className='col'><h6>Observaciones</h6></div>
        </div>
        <div className='row'><textarea type="text" className='form-control' id='observaciones' rows={5} value={boleta.observaciones} onChange={onInputChange}></textarea></div>


        <label className="form-label"><h6>Detalles</h6></label>
        <div className='row border border-sucess text-center' >
          <div id='header' className='col border border-sucess'>Detalle</div>
          <div id='header' className='col border border-sucess'>Foto</div>
          <div id='header' className='col border border-sucess'>Acciones</div>
        </div>
        {
          detalles ?
            detalles.map((detalle, index) => (
              <div className='row border border-sucess text-center' >
                <div id='header' className='col border border-sucess'>
                  <textarea className='form-control' id='detalle' value={detalle.detalle} onChange={(event) => onchangeDetalle(event, index)}
                  ></textarea>
                </div>
                <div id='header' className='col border border-sucess'>
                  <img src={(detalle.src) ? detalle.src : '/actions/images/' + boleta.idboleta + detalle.archivo} alt='' width="100px" />
                </div>
                <div id='header' className='col border border-sucess' style={{ display: 'flex', alignItems: 'center' }}>
                  {
                    (detalle.iddetalleboleta) ? null : <input type='file' id='foto' accept="image/*" className='form-control' onChange={(event) => onchangeDetalle(event, index)} />
                  }

                  <a href='javascript:;'><img src='/delete.png' onClick={(event) => deleteDetalle(event, index)} width="30px" style={{ margin: 5 }} alt=''></img></a>
                </div>
              </div>
            )) : null
        }
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button className='btn btn-primary' type='button' onClick={addDetalle} variant='secondary'>Agregar Detalle</Button>
        </div>

        {renderTecnico()}

        <br></br><hr></hr><br></br>

        {renderCliente()}

        <div className='align-self-center text-center' style={{ marginTop: 20 }}>
          <button type="button" className="btn" hidden={save} onClick={saveBoleta}><img src='/save.png' className='rounded' width="50px" title='Guardar reporte' alt='' /></button>
          <button type="button" className="btn" onClick={clearBoleta}><img src='/limpiar.png' className='rounded' width="50px" title='Limpiar reporte' alt='' /></button>
          <a href='#' onClick={() => navigate(-1)}><img className="rounded" src='/back.png' width="50px" style={{ margin: 5 }} title="Regresar"></img></a>
        </div>
      </form>

      <div id='messagebar' className="col align-self-center text-center alert alert-secondary">
        <label>{mensaje}</label>
      </div>
      <div className='row' style={{ margin: 50 }}></div>
    </div>
  )
}