import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { getBoletas, getClientes, getReporte, getServer } from './Utilities';
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from 'react-router-dom';

export default function Reporte() {
  const { idreporte } = useParams();

  const [saved, setSaved] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [boletas, setBoletas] = useState([]);
  const [mensaje, setMensaje] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [repData, setReporte] = useState({
    idecliente: '',
    idboleta: '',
    observaciones: '',
    trabajorealizado: '',
    recomendaciones: '',
    detalles: []
  });

  useEffect(() => {
    fetchEmpresas();

    if(idreporte){
      const dataRep = getReporte(idreporte);
      dataRep.then(function (response){
        setReporte({
          ...response['0'],
          detalles: response['detalles']
        });

        setBoletas([{
          idboleta: response[0].idboleta
        }]);

        setStartDate(new Date(response[0].fecha));
      })      
    }
  }, [idreporte])

  const fetchEmpresas = () => {
    const data = getClientes();
    data
      .then(function (response) {
        setClientes(response);
      })
      .catch(function (error) {
        setMensaje('Error cargando datos !!!')
      });
  }

  const fetchBoletas = (event) => {
    const data = getBoletas(event.target.value, false);
    data
      .then(function (response) {
        setBoletas(response);
      })
      .catch(function (error) {
        setMensaje('Error cargando datos !!!')
      });

      onInputChange(event);
  }

  const onInputChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    setSaved(false);
    setMensaje('');

    setReporte({...repData, [id]: value});
  }

  const limpiarForm = (e) => {
    setSaved(false);
    setMensaje('');
    setReporte({
      detalles: [],
      idecliente: '',
      idboleta: '',
      observaciones: '',
      trabajorealizado: '',
      recomendaciones: '',
    });
  }

  const saveReporte = (e) => {
    const postData = {
      action: '',
      data: {}
    };

    postData.action = (repData.idreporte === undefined) ? 'save' : 'update';
    repData.fecha = startDate;
    postData.data = repData;

    const axios = require('axios').default;
    axios.post(getServer() + '/actions/reportes.php', postData)
      .then(function (response) {
        setReporte({...repData, idreporte: response.data});
        setSaved(true);
        setMensaje("Datos guardados existosamente !!!");
      })
      .catch(function (error) {
        setSaved(false);
        setMensaje("Error guardando datos ");
      })
      .then(function () {
        // always executed
      });
  }

  return (
    <div className="container-lg ">
      <div className="row" style={{ margin: 50 }}>
        <div className="col align-self-center text-center">
          <h3>Reporte {(repData.idreporte)? '#' + repData.idreporte : ''}</h3>
        </div>
      </div>

      <form >
        <div className="row">
          <div className="col">
            <div className="mb-3" >
              <label className="form-label">Cliente</label>
              <select className="form-select" id='idecliente' onChange={fetchBoletas} value={repData.idecliente} required>
                <option disabled={true} value="">Escoja un cliente</option>
                {
                  clientes.map(({ idecliente, cliente, index }) => (
                    <option key={index} value={idecliente}>{cliente}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="mb-3" >
              <label className="form-label">Boleta</label>
              <select className="form-select" id='idboleta' onChange={onInputChange} value={repData.idboleta} required>
                <option disabled={true} value="">Escoja la boleta</option>
                {
                  boletas.map(({ idboleta, index }) => (
                    <option key={index} value={idboleta}>Boleta #{idboleta}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className='col'><label>Fecha</label></div>
          <div className='col'><ReactDatePicker className='form-control' 
              selected={startDate} onChange={(date) => setStartDate(date)} required/></div>
         <div className='col'></div>
        </div>

        <div className='align-self-center text-center'>
          <button type="button" onClick={saveReporte} className="btn" hidden={saved} ><img src='/save.png' className='rounded' width="50px" title='Guardar reporte' alt=''/></button>
          <button type="button" className="btn" onClick={limpiarForm}><img src='/limpiar.png' className='rounded' width="50px" title='Limpiar reporte' alt=''/></button>
          {
            saved? 
              <button type="button" className="btn"><a href={'/verreporte/'+repData.idreporte}><img src='/view.png' className='rounded' width="50px" title='Visualizar reporte' alt=''/></a></button> 
              : null
          }
        </div>
      </form>

      <div id='messagebar' className="col align-self-center text-center alert alert-secondary">
        <label>{mensaje}</label>
      </div>

      <div className='row' style={{ margin: 50 }}></div>
    </div>
  );
}