import React, { useEffect, useState } from 'react';
import { getServer } from './Utilities';

export default function Equipo() {
  const [equipos, setEquipos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [mensaje, setMensaje] = useState('');
  const [currentEquipo, setCurrentEquipo] = useState({
    anno: "",
    cliente: "",
    contacto: "",
    correo: "",
    direccion: "",
    equipo: "",
    idecliente: '',
    idequipo: null,
    marca: "",
    modelo: "",
    serial: "",
    telefono: "",
    oldidecliente: null,
  });

  useEffect(() => {
    fetchClientes();
    fetchEquipos();
  }, []);

  const fetchClientes = () => {
    const axios = require('axios').default;

    axios.get(getServer() + '/actions/clientes.php')
      .then(function (response) {
        setClientes(response.data);
      })
      .catch(function (error) {
        // on error        
      })
      .then(function () {
        // always executed
      });
  }

  const fetchEquipos = () => {
    const axios = require('axios').default;

    axios.get(getServer() + '/actions/equipos.php')
      .then(function (response) {
        setEquipos(response.data);
      })
      .catch(function (error) {
        // on error        
      })
      .then(function () {
        // always executed
      });
  }

  const handleInputChange = (event) => {
    setMensaje("");
    const target = event.target;
    const value = target.value;

    switch (target.id) {
      case 'name':
        currentEquipo.equipo = value;
        break;
      case 'marca':
        currentEquipo.marca = value;
        break;
      case 'modelo':
        currentEquipo.modelo = value;
        break;
      case 'anno':
        currentEquipo.anno = value;
        break;
      case 'serial':
        currentEquipo.serial = value;
        break;
      case 'empresa':
        currentEquipo.oldidecliente = currentEquipo.idecliente;
        currentEquipo.idecliente = parseInt(value);
        break;
      default:
        break;
    }

    setCurrentEquipo(currentEquipo);

    const newData = equipos.map(obj => {
      if (obj.idecliente === currentEquipo.idecliente && obj.idequipo === currentEquipo.idequipo) {
        obj = currentEquipo;
      }

      return obj;
    });

    setEquipos(newData);
  }

  const clearForm = event => {
    setCurrentEquipo({
      anno: "",
      cliente: "",
      contacto: "",
      correo: "",
      direccion: "",
      equipo: "",
      idecliente: "",
      idequipo: null,
      marca: "",
      modelo: "",
      serial: "",
      telefono: "",
    });
    
    setMensaje("");
  }

  const viewEquipo = (event, idecliente, idequipo) => {
    clearForm();
    const equipo = equipos.filter(equipo => equipo.idecliente === idecliente && equipo.idequipo === idequipo);
    if(equipo.length > 0){
      setCurrentEquipo(equipo[0]);
    }
  }

  const deleteEquipo = (event, idecliente, idequipo) => {
    const postData = {
      action: '',
      data: {}
    };

    postData.action = 'delete';
    postData.data = {
      idecliente,
      idequipo,
    };

    const axios = require('axios').default;
    axios.post(getServer() + '/actions/equipos.php', postData)
    .then(function (response) {        
      clearForm();
      fetchEquipos();
      setMensaje("Datos eliminados existosamente !!!");
    })
    .catch(function (error) {
      setMensaje("Error guardando datos ");
    })
    .then(function () {
      // always executed
    });
  }

  const saveEquipo = event => {
    event.preventDefault();

    const postData = {
      action: '',
      data: {}
    };

    currentEquipo.marca = (currentEquipo.marca === '' || currentEquipo.marca === null)? 'N/A' : currentEquipo.marca;
    currentEquipo.anno = (currentEquipo.anno === '' || currentEquipo.anno === null)? 'N/A' : currentEquipo.anno;
    currentEquipo.modelo = (currentEquipo.modelo === '' || currentEquipo.modelo === null)? 'N/A' : currentEquipo.modelo;
    currentEquipo.serial = (currentEquipo.serial === '' || currentEquipo.serial === null)? 'N/A' : currentEquipo.serial;
    setCurrentEquipo(currentEquipo);

    postData.action = (currentEquipo.idequipo === undefined || currentEquipo.idequipo === null) ? 'save' : 'update';
    postData.data = currentEquipo;

    const axios = require('axios').default;

    axios.post(getServer() + '/actions/equipos.php', postData)
      .then(function (response) {        
        clearForm();
        fetchEquipos();
        setMensaje("Datos guardados existosamente !!!");
      })
      .catch(function (error) {
        setMensaje("Error guardando datos ");
      })
      .then(function () {
        // always executed
      });
  }

  return (
    <div className="container-lg ">
      <div className="row" style={{ margin: 50 }}>
        <div className="col align-self-center text-center">
          <h3>Mantenimiento de Equipo</h3>
        </div>
      </div>
      <form>
        <div className="row">
          <div className="col">
            <div className="mb-3" >
              <label className="form-label">Nombre</label>
              <input type="text" className="form-control" id="name" value={currentEquipo.equipo} required onChange={handleInputChange}/>
            </div>
            <div className="mb-3" >
              <label className="form-label">Marca</label>
              <input type="text" className="form-control" id="marca" value={currentEquipo.marca} onChange={handleInputChange}/>
            </div>
            <div className="mb-3" >
              <label className="form-label">Modelo</label>
              <input type="text" className="form-control" id="modelo" value={currentEquipo.modelo} onChange={handleInputChange}/>
            </div>
          </div>
          <div className="col">
            <div className="mb-3" >
              <label className="form-label">Empresa</label>
              <select id='empresa' className='form-select' value={currentEquipo.idecliente} required onChange={handleInputChange}>
                <option disabled={true} value="">Escoja una empresa</option>
                {                  
                  clientes.map(({ idecliente, cliente, index }) => (
                    <option key={index} value={idecliente}>{cliente}</option>
                  ))
                }
              </select>
            </div>
            <div className="mb-3" >
              <label className="form-label">Año</label>
              <input type="text" className="form-control" id="anno" value={currentEquipo.anno} onChange={handleInputChange}/>
            </div>
            <div className="mb-3" >
              <label className="form-label">Serial</label>
              <input type="text" className="form-control" id="serial" value={currentEquipo.serial} onChange={handleInputChange}/>
            </div>
            <div className="mb-3" align="center">
              <button type="button" onClick={saveEquipo} className="btn"><img src='/save.png' className='rounded' width="50px" /></button>
              <button type="button" className="btn"><img src='/limpiar.png' className='rounded' width="50px" alt='Limpiar formulario' title='Limpiar' onClick={clearForm} /></button>
            </div>
          </div>
        </div>
      </form>
      <div className="col align-self-center text-center alert alert-secondary">
        <label>{mensaje}</label>
      </div>
      <div className="row" style={{ margin: 50 }}>
        <div className="col align-self-center text-center">
          <h3>Equipos Registrados</h3>
        </div>
      </div>
      <div className='row border border-sucess text-center' >
        <div id='header' className='col border border-sucess'>Acción</div>
        <div id='header' className='col border border-sucess'>Nombre</div>
        <div id='header' className='col border border-sucess'>Cliente</div>
      </div>
      {
        equipos.map(({idecliente, idequipo, equipo, cliente}) => (
          <div className='row'>
            <div className='col border border-sucess align-self-center text-center'>
              <a 
                href='#'
                onClick={event => viewEquipo(event, idecliente, idequipo)}
              >
                <img src='edit.png' width="25px" style={{margin: 5}}></img>
              </a>
              <a 
                href='#'
                onClick={event => deleteEquipo(event, idecliente, idequipo)}
                >
                  <img src='delete.png' width="25px"></img>
              </a>
            </div>
            <div className='col border border-sucess'>{equipo}</div>
            <div className='col border border-sucess'>{cliente}</div>
          </div>
        ))
      }
      <div className='row' style={{ margin: 50 }}></div>
    </div>
  );
}