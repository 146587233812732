import './Cliente.css';
import React, { useEffect, useState } from 'react';
import { getServer, getClientes } from './Utilities';

export default function Cliente() {
  const [data, setData] = useState([]);
  const [mensaje, setMensaje] = useState('');
  const [currentCliente, setCurrentCliente] = useState({
    cliente: '',
    direccion: '',
    telefono: '',
    correo: '',
    contacto: '',
  });

  useEffect(() => {
    fetchEmpresas();
  }, []);

  const fetchEmpresas = () => {
    const data = getClientes();
    data
      .then(function (response){
        setData(response);
      })
      .catch(function (error){
        setMensaje('Error cargando datos !!!')
      });
  }

  const handleInputChange = (event) => {
    setMensaje("");
    const target = event.target;
    const value = target.value;

    switch (target.id) {
      case 'nombre':
        currentCliente.cliente = value;
        break;
      case 'direccion':
        currentCliente.direccion = value;
        break;
      case 'telefono':
        currentCliente.telefono = value;
        break;
      case 'correo':
        currentCliente.correo = value;
        break;
      case 'contacto':
        currentCliente.contacto = value;
        break;
      default:
        break;
    }

    setCurrentCliente(currentCliente);

    const newData = data.map(obj => {
      if (obj.idecliente === currentCliente.idecliente) {
        obj = currentCliente;
      }

      return obj;
    });

    setData(newData);
  };

  const clearForm = event => {
    setCurrentCliente({
      cliente: '',
      direccion: '',
      telefono: '',
      correo: '',
      contacto: '',
    });
    setMensaje("");
  }

  const viewClient = (event, id) => {
    const c = data.filter(obj => obj.idecliente === id);
    setMensaje("");

    if (c.length > 0) {
      setCurrentCliente(c[0]);
    }
  };

  const deleteClient = (event, idecliente) => {
    console.log(idecliente);
    const postData = {
      action: 'delete',
      data: idecliente
    };

    const axios = require('axios').default;

    axios.post(getServer() + '/actions/clientes.php', postData)
      .then(function (response) {
        clearForm();
        fetchEmpresas();
        setMensaje("Datos eliminados existosamente !!!");
      })
      .catch(function (error) {
        setMensaje("Error eliminando datos ");
      })
      .then(function () {
        // always executed
      });
  }

  const saveClient = event => {
    event.preventDefault();

    const postData = {
      action: '',
      data: {}
    };

    postData.action = (currentCliente.idecliente === undefined) ? 'save' : 'update';
    postData.data = currentCliente;

    const axios = require('axios').default;

    axios.post(getServer() + '/actions/clientes.php', postData, {timeout: 3000})
      .then(function (response) {        
        clearForm();
        fetchEmpresas();
        setMensaje("Datos guardados existosamente !!!");
      })
      .catch(function (error) {
        setMensaje("Error guardando datos ");
      })
      .then(function () {
        // always executed
      });
  }

  return (
    <div id='container' className="container-lg ">
      <div id='title' className="row" style={{ margin: 50 }}>
        <div id='titlediv' className="col align-self-center text-center">
          <h3>Mantenimiento de Clientes</h3>
        </div>
      </div>
      <form onSubmit={saveClient}>
        <div id='firstrow' className="row">
          <div id='firstcol' className="col">
            <div id='inputname' className="mb-3" >
              <label className="form-label">Nombre</label>
              <input type="text" className="form-control" id='nombre' value={currentCliente.cliente} onChange={handleInputChange} required />
            </div>
            <div id='inputdireccion' className="mb-3" >
              <label className="form-label">Dirección</label>
              <input type="text" className="form-control" id="direccion" value={currentCliente.direccion} onChange={handleInputChange} required />
            </div>
            <div id='inputtelefono' className="mb-3" >
              <label className="form-label">Teléfono</label>
              <input type="phone" className="form-control" id="telefono" value={currentCliente.telefono} onChange={handleInputChange} required />
            </div>
          </div>
          <div id='secondcol' className="col">
            <div id='inputcorreo' className="mb-3" >
              <label className="form-label">Correo Electrónico</label>
              <input type="email" className="form-control" id="correo" value={currentCliente.correo} onChange={handleInputChange} required />
            </div>
            <div id='inputcontacto' className="mb-3" >
              <label className="form-label">Contacto</label>
              <input type="text" className="form-control" id="contacto" value={currentCliente.contacto} onChange={handleInputChange} required />
            </div>
            <div id='toolbar' className="mb-3" align="center">
              <button type="submit" className="btn"><img src='/save.png' className='rounded' width="50px" alt='Guardar informacion' title='Guardar'/></button>
              <button type="button" className="btn"><img src='/limpiar.png' className='rounded' width="50px" alt='Limpiar formulario' title='Limpiar' onClick={clearForm} /></button>
            </div>
          </div>
        </div>
      </form>
      <div id='messagebar' className="col align-self-center text-center alert alert-secondary">
        <label>{mensaje}</label>
      </div>

      <div id='titleclientes' className="row" style={{ margin: 50 }}>
        <div id='titlecli' className="col align-self-center text-center">
          <h3>Clientes Registrados</h3>
        </div>
      </div>
      <div id='tabletitle' className='row border border-sucess text-center' >
        <div id='header' className='col border border-sucess'>Acción</div>
        <div id='header' className='col border border-sucess'>Nombre</div>
      </div>
      {
        data.map(({ idecliente, cliente, index }) => (
          <div key={index} className='row'>
            <div id='accion' className='col border border-sucess align-self-center text-center'>
              <a
                href='#'
                onClick={event => viewClient(event, idecliente)}
              >
                <img src='edit.png' width="25px" style={{ margin: 5 }} alt='Editar cliente' title='Editar Cliente'></img>
              </a>
              <a
                href='#'
                onClick={event => deleteClient(event, idecliente)}
              >
                <img src='delete.png' width="25px" alt='Eliminar cliente' title='Eliminar Cliente'></img>
              </a>
            </div>
            <div id='nombre' className='col border border-sucess' >{cliente}</div>
          </div>
        ))
      }
      <div id='space' className='row' style={{ margin: 50 }}></div>
    </div>
  )
}
