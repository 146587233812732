import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { getReporte } from './Utilities';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

import './VerReporte.css';

export default function VerReporte() {
  const { idreporte } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [ emessage, setEmessage ] = useState('');

  const [data, setData] = useState({
    reporte: {},
    detalles: []
  });

  useEffect(() => {
    fetchReportes();
  }, [idreporte])


  const renderTecnico = (reporte) => {
    const {nombretecnico, firma } = reporte;

    if (nombretecnico)
      return <label>{nombretecnico}</label>

    if (firma)
      return <img src={firma} />

    return null;
  }

  const renderCliente = (reporte) => {
    const {nombrecliente, fcliente} = reporte;

    if(nombrecliente)
      return <label>{nombrecliente}</label>

    if (fcliente)
      return <img src={data.reporte.fcliente} />

    return null;
  }

  const fetchReportes = () => {

    const data = getReporte(idreporte);

    data
      .then(function (response) {
        setData({ reporte: response['0'], detalles: response['detalles'] });
        buildCripto(response['0']);
      })
      .catch(function (error) {
      });
  }

  const buildCripto = (d) => {
    let cripto = searchParams.get('cripto');

    if(cripto){
      const l = Array.from(cripto)[0];
      cripto = cripto.substring(1, cripto.length);

      for (let index = 0; index < l; index++) {
        cripto = base64_decode(cripto);
      }

      if(idreporte !== cripto.split("|")[0]){
        window.location = '/';
      }

    }else{
      let encoded = d.idreporte + "|"+ d.idboleta + "|"+ d.idecliente;
      const min = 1;
      const max = 5;
      const rand = (min + Math.random() * (max - min)) | 0;

      for (let index = 0; index < rand; index++) {
        encoded = base64_encode(encoded);
      }

      setEmessage(`${window.location.href}?cripto=${rand}${encoded}`);
    }
  }

  return (
    <div className="container-lg">
      <div className="row" style={{ margin: 50 }}>
        <div className="col align-self-center text-center">
          <h3>Solutecsa - Facilidades Electromecánicas</h3><br></br>
          <h4>Reporte de Mantenimiento</h4><br></br>
          <img id="dimage" src='/dashboard.png' alt='' className='rounded' />
        </div>
      </div>
      <h5>Datos del cliente</h5>

      <div className="row border-secondary" >
        <div className="col border border-secondary rounded">
          <strong>Nombre</strong>: <br></br>
          <strong>Dirección</strong>:<br></br>
          <strong>Fecha</strong>: <br></br>
          <strong>Boleta #</strong>: <br></br>
          <strong>Preparado por</strong>: <br></br>
          <strong>Hora Inicio</strong>: <br></br>
          <strong>Hora Final</strong>: <br></br>
        </div>
        <div className="col border border-secondary rounded">
          {data.reporte.cliente}<br></br>
          {data.reporte.direccion}<br></br>
          {data.reporte.fecha}<br></br>
          {data.reporte.idboleta}<br></br>
          {data.reporte.tecnico}<br></br>
          {data.reporte.inicio}<br></br>
          {data.reporte.final}<br></br>
        </div>
      </div>
      <br></br>

      <div className="row border-secondary " >
        <div className="col border border-secondary rounded">
          <strong>Tipo de Servicio</strong>: <br></br>
          <strong>Equipo</strong>:<br></br>
          <strong>Marca</strong>: <br></br>
          <strong>Modelo</strong>: <br></br>
        </div>
        <div className="col border border-secondary rounded">
          {data.reporte.tipomantenimiento}<br></br>
          {data.reporte.equipo}<br></br>
          {data.reporte.marca}<br></br>
          {data.reporte.modelo}<br></br>
        </div>
      </div>
      <br></br>
      <h5>Detalle Reporte</h5>
      <div className="row border-secondary " >
        <div className="col border border-secondary rounded">
          <span style={{ whiteSpace: 'pre-wrap' }}>{data.reporte.trabajorealizado}</span>
        </div>
      </div>
      <br></br>
      <h5>Detalle del Trabajo Realizado</h5>
      <div className="row border-secondary " >
        <div className="col border border-secondary rounded">
          <span style={{ whiteSpace: 'pre-wrap' }}>{data.reporte.reporte}</span>
        </div>
      </div>
      <br></br>

      <h5>Detalles</h5>
      <table className="table table-bordered" style={{ marginTop: 20 }}>
        <thead>
          <tr>
            <th>Detalle</th>
            <th>Imagen</th>
          </tr>
        </thead>
        {
          data.detalles.map(({ idboleta, detalle, archivo }) => (
            <tbody>
              <tr>
                <td width={'40%'}><span style={{ whiteSpace: 'pre-line' }}>{detalle}</span></td>
                <td width={'60%'} className="text-center"><img className='rounded' src={'/actions/images/' + idboleta + archivo} width="400" alt='' /></td>
              </tr>
            </tbody>
          ))
        }
      </table>

      <br></br>
      <h5>Recomendaciones</h5>
      <div className="row border-secondary " >
        <div className="col border border-secondary rounded">
          <span style={{ whiteSpace: 'pre-wrap' }}>{data.reporte.recomendaciones}</span>
        </div>
      </div>
      <br></br>
      <h5>Observaciones Adicionales</h5>
      <div className="row border-secondary " >
        <div className="col border border-secondary rounded">
          <span style={{ whiteSpace: 'pre-wrap' }}>{data.reporte.observaciones}</span>
        </div>
      </div>

      <table className="table table-bordered" style={{ marginTop: 20 }}>
        <thead>
          <tr>
            <th>Técnico</th>
            <th>Cliente</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{renderTecnico(data.reporte)}</td>
            <td>{renderCliente(data.reporte)}</td>
          </tr>
        </tbody>
      </table>

      <div className='align-self-center text-center'>
        <button type="button" className="btn">
          <a href='#' onClick={() => navigate(-1)}>
            <img src='/back.png' className='rounded' width="50px" title='Regresar al reporte' alt='' />
          </a>
        </button>
      </div>

      <div id='messagebar' className="col align-self-center text-center alert alert-secondary">
        <span style={{wordWrap: 'break-word'}}>{emessage}</span>
      </div>

      <div className="row" style={{ margin: 50 }}></div>
    </div>
  );
}