import './App.css';
import Footer from './components/Footer';
import Menu from './components/Menu';
import Tecnico from './components/Tecnico';
import Equipo from './components/Equipo';
import Reporte from './components/Reporte';
import Cliente from './components/Cliente';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import React from 'react';

import Login from './components/Login';
import { useState } from 'react';
import Boleta from './components/Boleta';
import VerReporte from './components/VerReporte';
import BusquedaBoleta from './components/BusquedaBoleta';
import BusquedaReporte from './components/BusquedaReporte';
import Pizarra from './components/Pizarra'

function setLocalStorage(token, tecnico){
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('tecnico', tecnico);
}

function getLocalStorage(){
  return sessionStorage.getItem('token');
}

function App() {
  const [token, setToken] = useState('');

  let noheader = false;

  if(!token && !getLocalStorage() && !window.location.href.includes("cripto")){
    return (
      <Login setToken={setToken} setLocalStorage={setLocalStorage} />
    );
  }

  if(!token && window.location.href.includes("cripto")){
    noheader = true;
  }else {
    noheader = false;
  }

  let tecnico = sessionStorage.getItem('tecnico');

  return (
    <div className="container ">
      <Menu noheader={noheader} tecnico={tecnico}/>

      {
        (window.location.pathname !== '/')? <div></div> :
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 100 }}>
            <div><img id="dimage" src='/dashboard.png' alt=''></img></div>
          </div>
      }
      <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/reporte/:idreporte" element={<Reporte />} />
          <Route path="/reporte" element={<Reporte />} />
          <Route path="/cliente" element={<Cliente />} />
          <Route path="/tecnico" element={<Tecnico />} />
          <Route path="/equipo" element={<Equipo />} />
          <Route path="/boleta" element={<Boleta />} />
          <Route path="/boleta/:idboleta" element={<Boleta />} />
          <Route path="/verreporte/:idreporte" element={<VerReporte />} />
          <Route path="/busquedaboleta" element={<BusquedaBoleta />} />
          <Route path="/busquedareporte" element={<BusquedaReporte />} />
          <Route path="/pizarra" element={<Pizarra  tecnico={tecnico}/>} />
          <Route />
        </Routes>
      </BrowserRouter>
      </React.StrictMode>
      <Footer />
    </div >
  );
}

export default App;
