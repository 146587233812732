import axios from "axios";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { getBoletas, getClientes, getReporte, getReporteByCliente, getServer } from "./Utilities";
import Moment from 'moment';

export default function BusquedaBoleta() {

  const [reportes, setReportes] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [busquedacliente, setbusquedacliente] = useState(true);
  const [results, setResults] = useState([]);
  const [idcliente, setidcliente] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    fetchClientes();
  }, [])

  const doSearch = () => {
    if(busquedacliente){
      searchByCliente(idcliente);
    }else{
      axios.get(getServer() + '/actions/busquedareportes.php?fecha1=' + Moment(startDate).format("YYYY-MM-DD") + "&fecha2=" + Moment(endDate).format("YYYY-MM-DD"))
      .then(function (response) {
        setReportes(response.data);
      })
      .catch(function (error) {
        // on error        
      })
      .then(function () {
        // always executed
      });
    }
  }

  const fetchClientes = () => {
    const axios = require('axios').default;

    axios.get(getServer() + '/actions/clientes.php')
      .then(function (response) {
        setClientes(response.data);
      })
      .catch(function (error) {
        // on error        
      })
      .then(function () {
        // always executed
      });
  }

  const fetchReportes = () => {
    const data = getReporte();
    data.then(function (response) {
      setReportes(response);
    }).catch(function (error) {

    });
  }

  const searchByCliente = (id) => {
    const d = getReporteByCliente(id);
    d.then(function (response) {
      setReportes(response);
    }).catch(function (error) {

    });
  }

  return (
    <div id='container' className="container-lg ">
      <div id='title' className="row" style={{ margin: 50 }}>
        <div id='titlediv' className="col align-self-center text-center">
          <h3>Búsqueda de Reportes</h3>
        </div>
      </div>

      <div className="row">
        <div className="col"><label><strong>Búsqueda por:</strong></label></div>
        <div class="form-check col">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={busquedacliente} onChange={() => setbusquedacliente(true)} />
          <label class="form-check-label" for="flexRadioDefault1">
            Cliente
          </label>
        </div>
        <div class="form-check col">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={!busquedacliente} onChange={() => setbusquedacliente(false)} />
          <label class="form-check-label" for="flexRadioDefault2">
            Fechas
          </label>
        </div>
      </div>
      <div className='row' style={{ margin: 10 }}></div>

      <form>
        <div className="row " hidden={!busquedacliente} >
          <div className="col" >
            <div className="mb-3" >
              <label className="form-label"><strong>Cliente</strong></label>
              <select id='empresa' className='form-select' value={idcliente} onChange={(event) => setidcliente(event.target.value)}>
                <option disabled={true} value="">Escoja una empresa</option>
                {
                  clientes.map(({ idecliente, cliente, index }) => (
                    <option key={index} value={idecliente}>{cliente}</option>
                  ))
                }
              </select>
            </div>
          </div>

        </div>
      </form>

      <form>
        <div>
          <div className="row " hidden={busquedacliente} >
            <div className="col" >
              <label className="form-label"><strong>Entre</strong></label>
            </div>
            <div className="col" >
              <ReactDatePicker className='form-control'
                selected={startDate} onChange={(date) => setStartDate(date)} />
            </div>
            y
            <div className="col" >
              <ReactDatePicker className='form-control'
                selected={endDate} onChange={(date) => setEndDate(date)} />
            </div>
          </div>
        </div>
      </form>

      <div className="col">
        <div className="mb-3" align="center">
          <button type="button" className="btn"><img src='/search.jpg' className='rounded' width="50px" onClick={doSearch}/></button>
        </div>
      </div>

      <div className='row' style={{ margin: 10 }}></div>
      <h4 style={{ color: 'grey' }}>Resultados</h4>

      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col">Cliente</th>
              <th scope="col">Número Boleta</th>
              <th scope="col">Mantenimiento</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              reportes.map(({ cliente, idreporte, idboleta, fecha }) => (
                <tr>
                  <th className='col'>{cliente}</th>
                  <th className='col'>Boleta #{idboleta}</th>
                  <th className='col'>{fecha}</th>
                  <th className='col'>
                    <a href={'/verreporte/' + idreporte} ><img src='view.png' width="25px" style={{ margin: 5 }}></img></a>
                    <a href={'/reporte/' + idreporte} ><img src='edit.png' width="25px" style={{ margin: 5 }}></img></a>
                  </th>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div className='row' style={{ margin: 50 }}></div>
    </div>
  )
}
